import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin, Select } from 'antd'
import Input from '../../common/Input';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';

const { Option } = Select;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            status: -1,
            editData: {},
        }
        this.columns = [
            {
                title: '姓名',
                dataIndex: 'userName',
                align: 'center',
            }, {
                title: '手机号',
                dataIndex: 'mobile',
                align: 'center',
            }, {
                title: '文明视频',
                dataIndex: 'video',
                align: 'center',
                render: src=>(
                    <video src={src} controls style={{height: 100,width: 200,}} />
                )
            }, {
                title: '不文明视频',
                dataIndex: 'no_video',
                align: 'center',
                render: src=>(
                    <video src={src} controls style={{height: 100,width: 200,}} />
                )
            }, {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
				render: status => (
					<React.Fragment>
                        {status==0&&<span className='color1'>待审核</span>}
                        {status==1&&<span className='color2'>评分中</span>}
                        {status==2&&<span className='color3'>已结束</span>}
                    </React.Fragment>
				)
            }, {
                title: '得分',
                dataIndex: 'score',
                align: 'center',
            }
        ]
    }
    getList(page, callback) {
        var req = {
            page: page,
            size: this.state.size,
            name: this.state.username || '',
            game_id: this.props.id,
            status: this.state.status,
        }
        global.Ajax.hhtc_request('admin/entry/list', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    render() {
        return (
            <React.Fragment>
                <CustomTable
                    columns={this.columns}
                    onRefresh={this.onRefresh.bind(this)}  //刷新
                    className='pubList'
                    style={{height: 'auto',maxHeight: '70vh'}}
                />
            </React.Fragment>
        )
    }
}
