import React from 'react';
import { Pagination,Spin,Empty,Table } from 'antd'
import Helper from '../class/Helper';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data || [],
            total: props.total || 0,
            page: props.page || 1,
            size: props.size || 10,
			requestLoadingShow: true,
        }
    }
    componentDidMount(){
        this.getList()
    }
    getList(){
        this.setState({
            requestLoadingShow: true,
            data: []
        },()=>{
            this.props.onRefresh(this.state.page,(res)=>{
                if(res.code==1){
                    this.setState({
                        total: res.data.all,
                        // data: this.initData(res.datas,res.all),
                        data: this.initData(res.data.datas,res.data.all),
                        requestLoadingShow: false,
                    })
                }else{
                    this.setState({
                        requestLoadingShow: false,
                    })
                }
            })
        })
    }
    initData(arry,total){
		let arryNew = []
		arry.map((item, index) => {
            let key=Helper.getNum(index, total, this.state.size, this.state.page, this.state.orderBy)
			arryNew.push(Object.assign({}, item, { key: key }))
		})
		return arryNew
    }
    // 刷新
    onRefresh(num){
        let page=this.state.page;
        if(num){
            page=num;
        }
        this.setState({
            page,
            total: 0
        },()=>{
            this.getList()
        })
    }
    render() {
        return (
            <React.Fragment>
                {/* 加载中 */}
                {this.state.requestLoadingShow && <Spin tip="加载中..." style={{ display: 'block', margin: '100px auto 0' }} />}
                {!this.state.requestLoadingShow&&<Table
                    pagination={false}
                    dataSource={this.state.data}
                    {...this.props}
                />}
                {this.state.total > 0 && <Pagination
                    total={this.state.total}
                    current={this.state.page}
                    pageSize={this.state.size}
                    showTotal={(total, range) => {
                        var num=range[0],
                            num1=range[1]
                        num=num<10?('0'+num):num;
                        num1=num1<10?('0'+num1):num1;
                        return `共${total}条记录，本页展示${num}-${num1}条记录`
                    }}
                    onChange={(page) => {
                        this.setState({
                            page,
                        }, () => {
                            this.getList()
                        })
                    }}
                    showSizeChanger={false}
                />}
            </React.Fragment>
        )
    }
}