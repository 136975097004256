import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin, Select, Tooltip } from 'antd'
import Input from '../../common/Input';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';
import EntryList from './EntryList';  //报名列表

const { Option } = Select;

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			orderBy: '',
			editData: {},
			status: -1,
		}
		this.columns = [
			{
				title: '赛事名称',
				dataIndex: 'name',
				align: 'center',
			}, {
				title: '赛事简介',
				dataIndex: 'introduce',
				align: 'center',
				width: '30%',
				ellipsis: {
					showTitle: false,
				},
				render: desc=>(
					<Tooltip title={desc}>{desc}</Tooltip>
				)
			}, {
				title: '赛事状态',
				dataIndex: 'status',
				align: 'center',
				render: status => (
					<span className={status == 1 ? 'color1' : 'color3'}>{status == 1 ? '评分中' : '已结束'}</span>
				)
			}, {
				title: '报名信息',
				dataIndex: 'people_num',
				align: 'center',
				render: (people_num,item) => `${people_num}/${item.max}`
			}, {
				title: '操作',
				dataIndex: 'id',
				align: 'center',
				width: 260,
				render: (id, item) => (
					<div className='flexAllCenter pubbtnbox'>
						<p onClick={()=>{
							this.setState({
								id,
								visible: true,
							})
						}}>报名列表</p>
						{/* <p onClick={this.changeGame.bind(this,id,'qc')}>排名去重</p> */}
						{/* <p onClick={this.changeGame.bind(this,id,'replay')}>重新发送</p> */}
						{item.status == 1 &&
							<p onClick={this.changeGame.bind(this,id,'end')}>结束评分</p>
							
						}
					</div>
				)
			}
		]
	}
	changeGame(id,type){
		let url='',
			req={
				id,
			};
		if(type=='qc'){  // 排名去重
			url='admin/game/duplicateremoval';
		}else if(type=='end'){  // 结束评分
			url='admin/game/endgame';
		}else if(type=='replay'){  // 重新发送
			url='admin/entry/sendmsg';
		}
		global.Ajax.hhtc_request(url,req).then(res=>{
			if(res.code==1){
				message.success(res.msg,1.2)
				this.refresh()
			}else{
				message.error(res.msg,1.2)
			}
		})
	}
	refresh(page) {
		this.tabblelist.onRefresh(page)
	}
	getList(page, callback) {
		var orderBy = '';
		if (this.state.orderBy != '') {
			orderBy = this.state.orderBy;
		}
		var req = {
			page: page,
			size: this.state.size,
			name: this.state.username || '',
			status: this.state.status,
		}
		global.Ajax.hhtc_request('admin/game/list', req).then(res => {
			callback(res)
		})
	}
	// 搜索
	search(name) {
		this.setState({
			username: name,
		}, () => {
			this.refresh(1)
		})
	}
	onRefresh(page, callback) {
		this.getList(page, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入赛事名称、简介搜索'
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value)
						}}
					/>
					<Select placeholder='请选择状态' className='pubSelt borderbai marginr12' style={{ width: 160 }} onChange={(status) => {
						if (status == undefined) {
							status = -1;
						}
						this.setState({
							status
						}, () => {
							this.refresh(1)
						})
					}}>
						<Option value={1}>评分中</Option>
						<Option value={2}>已结束</Option>
					</Select>
					<Button type='primary' loading={this.state.loading} onClick={this.down}>导出EXECL</Button>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>赛事列表</h2>
					<CustomTable
						ref={dom=>this.tabblelist=dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
					/>
				</div>
				{/* 报名列表 */}
				<CustomModal
					visible={this.state.visible}
					title='报名列表'
					onCancel={this.onCancel}
					width={1120}
				>
					<EntryList id={this.state.id} />
				</CustomModal>
                {/* IE导出EXECL */}
                <div style={{ width: 0, height: 0, overflow: 'hidden', }}>
                    <table id='table' style={{ height: 0, fontFamily: '微软雅黑' }}>
                        <tbody>
                            <tr style={{ textAlign: 'center' }}>
								<td>赛事名称</td>
								<td>赛事简介</td>
								<td>赛事状态</td>
								<td>报名信息</td>
                            </tr>
                            {this.state.rowIE && this.state.rowIE.map((item, index) => (
                                <tr style={{ textAlign: 'center' }} key={String(index)}>
                                    <td>{item.name || ''}</td>
                                    <td>{item.introduce || ''}</td>
                                    <td>{item.status==1?'评分中':'已结束'}</td>
                                    <td>{item.people_num}/{item.max}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
			</React.Fragment>
		)
	}
	onCancel=()=>{
		this.setState({
			visible: false,
			id: undefined,
		})
	}
    //  获取当前使用浏览器
    getExplorer() {
        var explorer = window.navigator.userAgent;
        //ie 
        if (explorer.indexOf("MSIE") >= 0) {
            return 'ie';
        } else if (explorer.indexOf("Firefox") >= 0) {
            return 'Firefox';
        } else if (explorer.indexOf("Chrome") >= 0) {
            return 'Chrome';
        } else if (explorer.indexOf("Opera") >= 0) {
            return 'Opera';
        } else if (explorer.indexOf("Safari") >= 0) {
            return 'Safari';
        }
    }
    down = () => {
        this.setState({
            loading: true,
        }, () => {
            let req = {
                name: this.state.username || '',
                status: this.state.status,
            }
            global.Ajax.hhtc_request('admin/game/excel', req).then(res => {
                if (res.code == 1) {
                    let row = res.data;
                    if (this.getExplorer() == 'ie' || this.getExplorer() == undefined) {  //ie浏览器
                        this.setState({
                            rowIE: row,
                        }, () => {
                            this.exportExeclForIE()
                        })
                    } else {
                        this.exportExecl(row)
                    }
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    // IE浏览器导出execl
    exportExeclForIE() {
        var curTbl = document.getElementById('table');
        var oXL;
        try {
            oXL = new window.ActiveXObject("Excel.Application"); //创建AX对象excel
        } catch (e) {
            alert("无法启动Excel!请修改IE配置\n\n如果您确定您的电脑中已经安装了Excel，" + "那么请调整IE的安全级别。\n\n具体操作：\n\n" + "工具 → Internet选项 → 安全 → 自定义级别 → 对没有标记为安全的ActiveX进行初始化和脚本运行 → 启用");
            return false;
        }
        var oWB = oXL.Workbooks.Add(); //获取workbook对象
        var oSheet = oWB.ActiveSheet;//激活当前sheet
        var sel = document.body.createTextRange();
        sel.moveToElementText(curTbl); //把表格中的内容移到TextRange中
        sel.select(); //全选TextRange中内容
        sel.execCommand("Copy");//复制TextRange中内容
        oSheet.Paste();//粘贴到活动的EXCEL中
        oXL.Visible = true; //设置excel可见属性
        var fname = oXL.Application.GetSaveAsFilename("赛事信息.xls", "Excel Spreadsheets (*.xls), *.xls");
        oWB.SaveAs(fname);
        oWB.Close();
        oXL.Quit();
    }
    // 非IE浏览器导出execl
    exportExecl(row) {
        let data = `<tr style="text-align: center;">
            <td>赛事名称</td>
            <td>赛事简介</td>
            <td>赛事状态</td>
            <td>报名信息</td>
        </tr>`;
        for (let i in row) {
            let item = row[i];
            data += `<tr style="text-align: center;">
                <td>${item.name}</td>
                <td>${item.introduce}</td>
                <td>${item.status==1?'评分中':'已结束'}</td>
                <td>${item.people_num}/${item.max}</td>
            </tr>`
        }

        var str = `<table style="font-family: '微软雅黑';"><tbody>${data}</tbody></table>`;
        var worksheet = 'Sheet1'
        var uri = 'data:application/vnd.ms-excel;base64,';
        //下载的表格模板数据
        var template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
                xmlns:x="urn:schemas-microsoft-com:office:excel"
                xmlns="http://www.w3.org/TR/REC-html40">
                <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                <x:Name>${worksheet}</x:Name>
                <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                </head><body>${str}</body></html>`;
        //下载模板
        function base64(s) { return window.btoa(unescape(encodeURIComponent(s))) }
        let link = document.createElement('a');
        link.href = uri + base64(template);
        link.download = '赛事信息.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
