import React from 'react';
import { Input, Button, Form, message } from 'antd';
import SelectList from '../../common/SelectList'

export default class Baoming extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pwd: '',
        }
    }
    
    onFinish = (req) => {
        
        this.setState({
            loading: true,
        },()=>{
            var url='ceshi/baoming';
            req.video="https://qiniu.honghukeji.net/fcsc/"+req.video+".mp4";
            req.no_video="https://qiniu.honghukeji.net/fcsc/"+req.no_video+".mp4";
            
            global.Ajax.hhtc_request(url,req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onOk()
                }else{
                    message.error(res.msg,1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{span: 6}}
                    labelAlign="left"
                     
                >
                    <Form.Item name="game_id" label="赛事ID" rules={[{ required: true, message: '请输入赛事ID!' }]}>
                        <Input autoComplete="off" placeholder='请输入赛事ID' type='number' />
                    </Form.Item>
                    <Form.Item name="userId" label="用户ID" rules={[{ required: true, message: '请输入用户ID!' }]}>
                        <Input autoComplete="off" placeholder='请输入用户ID' type='text' />
                    </Form.Item>
                    <Form.Item name="userName" label="用户名" rules={[{ required: true, message: '请输入用户名!' }]}>
                        <Input autoComplete="off" placeholder='请输入用户名' type='text' />
                    </Form.Item>
                    <Form.Item name="mobile" label="手机号" rules={[{ required: true, message: '请输入用户手机号!' }]}>
                        <Input autoComplete="off" placeholder='请输入用户手机号' type='text' />
                    </Form.Item>
                    <Form.Item   name="video" label="文明视频" 
                    rules={[{ required: true, message: '请选择文明视频!' }]}>
                        <SelectList showSearch placeholder="请选择视频" type="videoList" style={{ flex: 1 }} />
                    </Form.Item>
                    <Form.Item   name="no_video" label="不文明视频" 
                    rules={[{ required: true, message: '请选择不文明视频!' }]}>
                        <SelectList showSearch placeholder="请选择视频" type="videoList" style={{ flex: 1 }} />
                    </Form.Item>
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}