
import React from 'react';
import { Button, Pagination, Modal, Empty, Image, message, Spin, Switch, Select, DatePicker, Form, Input } from 'antd'
import './ceshi.css';
import AddGame from './AddGame';
import CustomModal from '../../common/Modal';
import Baoming from './Baoming';
export default class Ceshi extends React.Component{
    constructor(props){
        super(props)
        this.state={
            visible:false,
            bmVisible:false,
            type:"add"
        }
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            global.Ajax.hhtc_request('admin/rule/addrule', req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.getInfo()
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1200);
            })
        })
    }
    render()
    {
        return(
            <div className='ceshi_div'>
                <Button type='primary marginr12' onClick={()=>{
                    this.setState({
                        type:"add",
                        visible:true
                    })
                }}>创建赛事</Button>
                <Button type='primary marginr12' onClick={()=>{
                    this.setState({
                        type:"edit",
                        visible:true
                    })
                }}>修改赛事</Button>
                <Button type='primary' onClick={()=>{
                    this.setState({
                        bmVisible:true
                    })
                }}>赛事报名</Button>

                <CustomModal
					visible={this.state.visible}
					width={360}
					title={`${this.state.type == 'edit' ? '编辑' : '添加'}赛事`}
					onCancel={this.onCancel.bind(this)}
				>
					<AddGame    onCancel={this.onCancel.bind(this)} onOk={this.onCancel.bind(this)} />
				</CustomModal>
                <CustomModal
					visible={this.state.bmVisible}
					width={480}
					title={`赛事报名`}
					onCancel={this.onCancel.bind(this)}
				>
					<Baoming    onCancel={this.onCancel.bind(this)} onOk={this.onCancel.bind(this)} />
				</CustomModal>
            </div>
        );
    }
    onCancel()
    {
        this.setState({
            visible:false,
            bmVisible:false
        })
    }
}