import React from 'react';
import { Input, Button, Form, message } from 'antd';
import SelectList from '../../common/SelectList'

export default class AddGame extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pwd: ''
        }
    }
    
    onFinish = (req) => {
        
        this.setState({
            loading: true,
        },()=>{
            var url='ceshi/addGame';
           
            global.Ajax.hhtc_request(url,req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onOk()
                }else{
                    message.error(res.msg,1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{span: 6}}
                    labelAlign="left"
                     
                >
                    <Form.Item name="id" label="赛事ID" rules={[{ required: true, message: '请输入赛事ID!' }]}>
                        <Input autoComplete="off" placeholder='请输入赛事ID' type='number' />
                    </Form.Item>
                    <Form.Item name="name" label="赛事名称" rules={[{ required: true, message: '请输入赛事名称!' }]}>
                        <Input autoComplete="off" placeholder='请输入赛事名称' type='text' />
                    </Form.Item>
                    <Form.Item name="max" label="参赛人数：" rules={[{ required: true, message: '请输入赛事参赛人数!' }]}>
                    <Input autoComplete="off" placeholder='请输入赛事最大参赛人数' type='number' />
                    </Form.Item>
                    <Form.Item name="introduce" label="赛事简介" rules={[{ required: true, message: '请输入赛事简介!' }]}>
                        <Input.TextArea autoComplete="off" placeholder='请输入赛事简介' type='text' />
                    </Form.Item>
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}