import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin, Select,Image, Tooltip } from 'antd'
import Input from '../../common/Input';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';

const { Option } = Select;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            editData: {},
        }
        this.columns = [
            {
                title: '截图',
                dataIndex: 'thumb',
                align: 'center',
                width:80,
                render: src=>(
                    <Image src={src} width={40} height={40} />
                )
            }, {
                title: '类型',
                dataIndex: 'type',
                align: 'center',
                width:120,
				render: type => (
					<span className={type == 1 ? 'color1' : 'color3'}>{type == 1 ? '不文明视频' : '文明视频'}</span>
				)
            }, {
                title: '总分值',
                dataIndex: 'Totalscore',
                align: 'center',
                width:100,
            }, {
                title: '总得分',
                dataIndex: 'koufen',
                width:100,
                align: 'center',
            }, {
                title: '得分说明',
                dataIndex: 'description',
                align: 'center',
                width:500,
                ellipsis: {
                    showTitle: false,
                },
                render: content => (
                    <Tooltip title={content} placement='topLeft'>
                        {content}
                    </Tooltip>
                )
            }
        ]
    }
    getList(page, callback) {
        var req = {
            id: this.props.id,
            type:this.props.type
        }
        global.Ajax.hhtc_request('admin/entry/scoredetaillist', req).then(res => {
            let data={
                code: res.code,
                msg: res.msg,
                data:{
                    all: 0,
                    datas: res.data,
                }
            }
            callback(data)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    render() {
        return (
            <React.Fragment>
                <CustomTable
                    columns={this.columns}
                    onRefresh={this.onRefresh.bind(this)}  //刷新
                    className='pubList'
                    style={{height: 'auto',maxHeight: '70vh'}}
                />
            </React.Fragment>
        )
    }
}
