import React from 'react';
import { Button, Pagination, Modal, Empty, Image, message, Spin, Switch, Select, DatePicker, Form, Input } from 'antd'
import Helper from '../class/Helper';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
        this.getInfo()
    }
    getInfo() {
        global.Ajax.hhtc_request('admin/rule/getrule', {}).then(res => {
            if (res.code == 1) {
                this.formRef.setFieldsValue({
                    xiyan: res.data.xiyan,
                    dianhua: res.data.dianhua,
                    anquandai: res.data.anquandai,
                    fangxiangpan: res.data.fangxiangpan,
                    kanqianfang: res.data.kanqianfang,
                    dahaqian: res.data.dahaqian,
                    biyan: res.data.biyan,
                    ditou: res.data.ditou,
                    ratio: res.data.ratio,
                    width: res.data.width,
                    height: res.data.height,
                    durationscore: res.data.durationscore,
                    duration: res.data.duration,
                })
            }
        })
    }
    refresh() {
        this.getInfo()
    }
    render() {
        return (
            <React.Fragment>
                <div className='bgbai' style={{ height: 0, flex: 'auto', background: 'transparent' }}>
                    <div className='flexColumn' style={{ width: '60%', background: '#fff', height: '100%' }}>
                        <h2 className='pubTit' style={{ paddingBottom: 0, }}>评分规则管理</h2>
                        <div style={{ height: 0, flex: 'auto', overflow: 'auto' }} className='ovau'>
                            <Form
                                ref={dom => this.formRef = dom}
                                onFinish={this.onFinish}
                                style={{ padding: '0 30px' }}
                            >
                                <div className='flwp'>
                                    <div className='item48'>
                                        <p className='pubTit'>吸烟</p>
                                        <Form.Item label='分值' name='xiyan' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置单次分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>闭眼</p>
                                        <Form.Item label='分值' name='biyan' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置单次分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>打电话</p>
                                        <Form.Item label='分值' name='dianhua' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置单次分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>低头</p>
                                        <Form.Item label='分值' name='ditou' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置单次分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>未系安全带</p>
                                        <Form.Item label='分值' name='anquandai' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置单次分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>双手离开方向盘</p>
                                        <Form.Item label='分值' name='fangxiangpan' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置单次分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>未看正前方</p>
                                        <Form.Item label='分值' name='kanqianfang' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置单次分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>打哈欠</p>
                                        <Form.Item label='分值' name='dahaqian' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置单次分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>时长分值</p>
                                        <Form.Item label='时长分值' name='durationscore' rules={[{ required: true, message: '请输入时长分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置时长分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>视频时长</p>
                                        <Form.Item label='视频时长' name='duration' rules={[{ required: true, message: '请输入视频时长！' }]}>
                                            <Input autoComplete='off' placeholder='请设置视频时长' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>视频分辨率分值</p>
                                        <Form.Item label='分值' name='ratio' rules={[{ required: true, message: '请输入分值！' }]}>
                                            <Input autoComplete='off' placeholder='请设置分值' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>视频基础宽度</p>
                                        <Form.Item label='宽度' name='width' rules={[{ required: true, message: '请输入宽度！' }]}>
                                            <Input autoComplete='off' placeholder='请设置宽度' />
                                        </Form.Item>
                                    </div>
                                    <div className='item48'>
                                        <p className='pubTit'>视频基础高度</p>
                                        <Form.Item label='高度' name='height' rules={[{ required: true, message: '请输入高度！' }]}>
                                            <Input autoComplete='off' placeholder='请设置高度' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <Button type='primary' style={{ display: 'block', marginLeft: 'auto', marginTop: 20, marginBottom: 20,marginRight: 30, }} loading={this.state.loading} onClick={()=>{
                            this.formRef.submit();
                        }}>保存</Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            global.Ajax.hhtc_request('admin/rule/addrule', req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.getInfo()
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1200);
            })
        })
    }
}
