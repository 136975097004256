import React from 'react';
import { Input, Switch, Button, message } from 'antd'

export default class Index extends React.Component {
	constructor(props){
		super(props)
		this.state={
			visible: null,
			alioss: {
				ak: '',
				bucket: '',
				domain: '',
				endpoint: '',
				sk: ''
			},
			qiniu: {
				ak: '',
				bucket: '',
				domain: '',
				sk: '',
			},
			txcos: {
				ak: '',
				bucket: '',
				bucketName: '',
				domain: '',
				sk: ''
			},
			loading: false,
		}
	}
	componentDidMount(){
		this.refresh()
	}
	refresh(){
		global.Ajax.hhtc_request('setting/getUploadConfig',{}).then(res=>{
			if(res.code==1){
				this.setState(res.data)
			}
		})
	}
	changeSwitch(visible,value){
		this.setState({ visible })
	}
	// 保存
	save(type){
		this.setState({
			loading: true,
		},()=>{
			var req={};
			var url="";
			if(type==1){
				req=this.state.qiniu
				url="setting/saveQiniu"
			}else if(type==2){
				req=this.state.alioss
				url="setting/saveAlioss"
			}else if(type==3){
				req=this.state.txcos
				url="setting/saveTxcos"
			}else if(type==4){
				url='setting/saveLocal'
			}
			req.visible=this.state.visible;
			global.Ajax.hhtc_request(url,req).then(res=>{
				if(res.code==1){
					message.success(res.msg,1.2)
				}else{
					message.error(res.msg,1.2)
				}
				this.setState({
					loading: false
				})
			})
		})
	}
	render() {
		return (
			<div className='uploadBox' style={{height: 0,flex: 'auto'}}>
				<div className='editPwdBox box'>
					<h2 className='pubTit'>七牛云配置</h2>
					<div className='flexCenter'>
						<p>AK：</p>
						<Input
							placeholder='请输入AK'
							
							value={this.state.qiniu.ak}
							onChange={(e)=>{
								this.state.qiniu.ak=e.target.value;
								this.setState({
									qiniu: this.state.qiniu
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p>SK：</p>
						<Input
							placeholder='请输入SK'
							
							value={this.state.qiniu.sk}
							onChange={(e)=>{
								this.state.qiniu.sk=e.target.value;
								this.setState({
									qiniu: this.state.qiniu
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p>仓库名称：</p>
						<Input
							placeholder='请输入仓库名称'
							type='text'
							value={this.state.qiniu.bucket}
							onChange={(e)=>{
								this.state.qiniu.bucket=e.target.value;
								this.setState({
									qiniu: this.state.qiniu
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p>七牛域名：</p>
						<Input
							placeholder='请输入七牛域名'
							type='text'
							value={this.state.qiniu.domain}
							onChange={(e)=>{
								this.state.qiniu.domain=e.target.value;
								this.setState({
									qiniu: this.state.qiniu
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p>使用状态：</p>
						<Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.visible==1?true:false} onChange={this.changeSwitch.bind(this,1)} />
					</div>
					<div className='btnbox flexCenter flexEnd' style={{marginTop: 106}}>
						<Button className='huibtn marginr8'>重置</Button>
						<Button type='primary' onClick={this.save.bind(this,1)}>保存</Button>
					</div>
				</div>
				<div className='editPwdBox box'>
					<h2 className='pubTit'>阿里OSS配置</h2>
					<div className='flexCenter'>
						<p style={{width: 90}}>AccessKeyID：</p>
						<Input
							placeholder='请输入AccessKeyID'
							value={this.state.alioss.ak}
							onChange={(e)=>{
								this.state.alioss.ak=e.target.value
								this.setState({
									alioss: this.state.alioss
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p style={{width: 90}}>AccessKey：</p>
						<Input
							placeholder='请输入AccessKey'
							value={this.state.alioss.sk}
							onChange={(e)=>{
								this.state.alioss.sk=e.target.value
								this.setState({
									alioss: this.state.alioss
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p style={{width: 90}}>仓库名称：</p>
						<Input
							placeholder='请输入仓库名称'
							type='text'
							value={this.state.alioss.bucket}
							onChange={(e)=>{
								this.state.alioss.bucket=e.target.value
								this.setState({
									alioss: this.state.alioss
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p style={{width: 90}}>endpoint：</p>
						<Input
							placeholder='请输入endpoint'
							type='text'
							value={this.state.alioss.endpoint}
							onChange={(e)=>{
								this.state.alioss.endpoint=e.target.value
								this.setState({
									alioss: this.state.alioss
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p style={{width: 90}}>自定义域名：</p>
						<Input
							placeholder='请输入自定义域名'
							type='text'
							value={this.state.alioss.domain}
							onChange={(e)=>{
								this.state.alioss.domain=e.target.value
								this.setState({
									alioss: this.state.alioss
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p>使用状态：</p>
						<Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.visible==2?true:false} onChange={this.changeSwitch.bind(this,2)} />
					</div>
					<div className='btnbox flexCenter flexEnd'>
						<Button className='huibtn marginr8'>重置</Button>
						<Button type='primary' onClick={this.save.bind(this,2)}>保存</Button>
					</div>
				</div>
				<div className='editPwdBox box'>
					<h2 className='pubTit'>腾讯云配置</h2>
					<div className='flexCenter'>
						<p style={{width: 90}}>AccessKeyID：</p>
						<Input
							placeholder='请输入AccessKeyID'
							value={this.state.txcos.ak}
							onChange={(e)=>{
								this.state.txcos.ak=e.target.value;
								this.setState({
									txcos: this.state.txcos
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p style={{width: 90}}>AccessKey：</p>
						<Input
							placeholder='请输入AccessKey'
							type='text'
							value={this.state.txcos.sk}
							onChange={(e)=>{
								this.state.txcos.sk=e.target.value;
								this.setState({
									txcos: this.state.txcos
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p style={{width: 90}}>仓库名称：</p>
						<Input
							placeholder='请输入仓库名称'
							type='text'
							value={this.state.txcos.bucketName}
							onChange={(e)=>{
								this.state.txcos.bucketName=e.target.value;
								this.setState({
									txcos: this.state.txcos
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p style={{width: 90}}>bucket：</p>
						<Input
							placeholder='请输入bucket'
							type='text'
							value={this.state.txcos.bucket}
							onChange={(e)=>{
								this.state.txcos.bucket=e.target.value;
								this.setState({
									txcos: this.state.txcos
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p style={{width: 90}}>自定义域名：</p>
						<Input
							placeholder='请输入自定义域名'
							type='text'
							value={this.state.txcos.domain}
							onChange={(e)=>{
								this.state.txcos.domain=e.target.value;
								this.setState({
									txcos: this.state.txcos
								})
							}}
						/>
					</div>
					<div className='flexCenter'>
						<p>使用状态：</p>
						<Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.visible==3?true:false} onChange={this.changeSwitch.bind(this,3)} />
					</div>
					<div className='btnbox flexCenter flexEnd'>
						<Button className='huibtn marginr8'>重置</Button>
						<Button type='primary' onClick={this.save.bind(this,3)}>保存</Button>
					</div>
				</div>
				<div className='editPwdBox box'>
					<h2 className='pubTit'>本地配置</h2>
					<div className='flexCenter'>
						<p>使用状态：</p>
						<Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.visible==4?true:false} onChange={this.changeSwitch.bind(this,4)} />
					</div>
					<div className='btnbox flexCenter flexEnd' style={{marginTop: 106}}>
						<Button className='huibtn marginr8'>重置</Button>
						<Button type='primary' onClick={this.save.bind(this,4)}>保存</Button>
					</div>
				</div>
			</div>
		)
	}
}
