import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin, Select } from 'antd'
import Input from '../../common/Input';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';

const { Option } = Select;
const levelTxt = ['特等奖', '一等奖', '二等奖', '三等奖', '四等奖', '五等奖', '一六等奖', '七等奖']

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			orderBy: '',
			editData: {},
			gameList: [],
			game_id:0
		}
		this.columns = [
			{
				title: '赛事名称',
				dataIndex: 'gamename',
				align: 'center',
			}, {
				title: '奖项',
				dataIndex: 'rewardLevel',
				align: 'center',
				render: level => (
					<React.Fragment>{levelTxt[level - 1]}</React.Fragment>
				)
			}, {
				title: '姓名',
				dataIndex: 'userName',
				align: 'center',
			}, {
				title: '手机号',
				dataIndex: 'mobile',
				align: 'center',
			}, {
				title: '文明视频',
				dataIndex: 'video',
				align: 'center',
				render: src => (
					<video src={src} controls style={{ height: 100, width: 200, }} />
				)
			}, {
				title: '不文明视频',
				dataIndex: 'no_video',
				align: 'center',
				render: src => (
					<video src={src} controls style={{ height: 100, width: 200, }} />
				)
			}, {
				title: '得分',
				dataIndex: 'score',
				align: 'center',
			}
		]
	}
	componentDidMount() {
		// 获取所有大赛
		this.getGame()
	}
	getGame() {
		let req = {
			name: '',
			status: '',
		}
		global.Ajax.hhtc_request('admin/game/excel', req).then(res => {
			if (res.code == 1) {
				this.setState({
					gameList: res.data,
					game_id:res.data.length>0?res.data[0].id:0
				},()=>{
					this.refresh();
				})
			}
		})
	}
	refresh(page) {
		this.tabblelist.onRefresh(page)
	}
	getList(page, callback) {
		var req = {
			page: page,
			size: this.state.size,
			name: this.state.username || '',
			rewardLevel: this.state.rewardLevel || '',
			game_id: this.state.game_id || '',
		}
		if(req.game_id==0){
			callback({code:1,msg:"",data:{all:0,datas:[]}})
			return;
		}
		global.Ajax.hhtc_request('admin/entry/ranklist', req).then(res => {
			callback(res)
		})
	}
	// 搜索
	search(name) {
		this.setState({
			username: name,
		}, () => {
			this.refresh(1)
		})
	}
	onRefresh(page, callback) {
		this.getList(page, callback)
	}
	render() {
		const { gameList } = this.state;
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入姓名、手机号'
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value)
						}}
					/>
					<Select value={this.state.game_id} placeholder='请选择赛事' className='pubSelt borderbai marginr12' style={{ width: 160 }} onChange={(game_id) => {
						this.setState({
							game_id,
						}, () => {
							this.refresh(1)
						})
					}}>
						{gameList.map((item, index) => (
							<Option value={item.id} key={String(index)}>{item.name}</Option>
						))}
					</Select>
					<Select allowClear placeholder='请选择奖项' className='pubSelt borderbai marginr12' style={{ width: 160 }} onChange={(rewardLevel) => {
						this.setState({
							rewardLevel
						}, () => {
							this.refresh(1)
						})
					}}>
						{levelTxt.map((item, index) => (
							<Option value={index + 1} key={String(index)}>{item}</Option>
						))}
					</Select>
					<Button type='primary' loading={this.state.loading} onClick={this.down}>导出EXECL</Button>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>赛事排名</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
					/>
				</div>
				{/* IE导出EXECL */}
				<div style={{ width: 0, height: 0, overflow: 'hidden', }}>
					<table id='table' style={{ height: 0, fontFamily: '微软雅黑' }}>
						<tbody>
							<tr style={{ textAlign: 'center' }}>
								<td>赛事名称</td>
								<td>奖项</td>
								<td>姓名</td>
								<td>手机号</td>
								<td>文明驾驶视频</td>
								<td>不文明驾驶视频</td>
								<td>得分</td>
							</tr>
							{this.state.rowIE && this.state.rowIE.map((item, index) => (
								<tr style={{ textAlign: 'center' }} key={String(index)}>
									<td>{item.gamename}</td>
									<td>{levelTxt[item.rewardLevel - 1]}</td>
									<td>{item.userName}</td>
									<td>{item.mobile}</td>
									<td>
										<a href={item.video}>{item.video}</a>
									</td>
									<td>
										<a href={item.no_video}>{item.no_video}</a>
									</td>
									<td>{item.score || 0}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</React.Fragment>
		)
	}
	//  获取当前使用浏览器
	getExplorer() {
		var explorer = window.navigator.userAgent;
		//ie 
		if (explorer.indexOf("MSIE") >= 0) {
			return 'ie';
		} else if (explorer.indexOf("Firefox") >= 0) {
			return 'Firefox';
		} else if (explorer.indexOf("Chrome") >= 0) {
			return 'Chrome';
		} else if (explorer.indexOf("Opera") >= 0) {
			return 'Opera';
		} else if (explorer.indexOf("Safari") >= 0) {
			return 'Safari';
		}
	}
	down = () => {
		this.setState({
			loading: true,
		}, () => {
			let req = {
				name: this.state.username || '',
				rewardLevel: this.state.rewardLevel || '',
				game_id: this.state.game_id || '',
			}
			global.Ajax.hhtc_request('admin/entry/ranklistexcel', req).then(res => {
				if (res.code == 1) {
					let row = res.data;
					if (this.getExplorer() == 'ie' || this.getExplorer() == undefined) {  //ie浏览器
						this.setState({
							rowIE: row,
						}, () => {
							this.exportExeclForIE()
						})
					} else {
						this.exportExecl(row)
					}
				}
				setTimeout(() => {
					this.setState({
						loading: false,
					})
				}, 1500);
			})
		})
	}
	// IE浏览器导出execl
	exportExeclForIE() {
		var curTbl = document.getElementById('table');
		var oXL;
		try {
			oXL = new window.ActiveXObject("Excel.Application"); //创建AX对象excel
		} catch (e) {
			alert("无法启动Excel!请修改IE配置\n\n如果您确定您的电脑中已经安装了Excel，" + "那么请调整IE的安全级别。\n\n具体操作：\n\n" + "工具 → Internet选项 → 安全 → 自定义级别 → 对没有标记为安全的ActiveX进行初始化和脚本运行 → 启用");
			return false;
		}
		var oWB = oXL.Workbooks.Add(); //获取workbook对象
		var oSheet = oWB.ActiveSheet;//激活当前sheet
		var sel = document.body.createTextRange();
		sel.moveToElementText(curTbl); //把表格中的内容移到TextRange中
		sel.select(); //全选TextRange中内容
		sel.execCommand("Copy");//复制TextRange中内容
		oSheet.Paste();//粘贴到活动的EXCEL中
		oXL.Visible = true; //设置excel可见属性
		var fname = oXL.Application.GetSaveAsFilename("赛事排名信息.xls", "Excel Spreadsheets (*.xls), *.xls");
		oWB.SaveAs(fname);
		oWB.Close();
		oXL.Quit();
	}
	// 非IE浏览器导出execl
	exportExecl(row) {
		let data = `<tr style="text-align: center;">
            <td>赛事名称</td>
            <td>奖项</td>
            <td>姓名</td>
            <td>手机号</td>
            <td>文明驾驶视频</td>
            <td>不文明驾驶视频</td>
            <td>得分</td>
        </tr>`;
		for (let i in row) {
			let item = row[i];
			data += `<tr style="text-align: center;">
                <td>${item.gamename}</td>
                <td>${levelTxt[item.rewardLevel - 1]}</td>
                <td>${item.userName}</td>
                <td>${item.mobile}</td>
                <td>
					<a href="${item.video}">${item.video}</a>
				</td>
                <td>
					<a href="${item.no_video}">${item.no_video}</a>
				</td>
                <td>${item.score || 0}</td>
            </tr>`
		}

		var str = `<table style="font-family: '微软雅黑';"><tbody>${data}</tbody></table>`;
		var worksheet = 'Sheet1'
		var uri = 'data:application/vnd.ms-excel;base64,';
		//下载的表格模板数据
		var template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
                xmlns:x="urn:schemas-microsoft-com:office:excel"
                xmlns="http://www.w3.org/TR/REC-html40">
                <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                <x:Name>${worksheet}</x:Name>
                <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                </head><body>${str}</body></html>`;
		//下载模板
		function base64(s) { return window.btoa(unescape(encodeURIComponent(s))) }
		let link = document.createElement('a');
		link.href = uri + base64(template);
		link.download = '赛事排名信息.xlsx';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}
