import React from 'react';
import { Button, Popconfirm, Modal, message, Empty, Spin, Select, Form, Input } from 'antd'
import CustomInput from '../../common/Input';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';
import ScoreList from './ScoreList';
import PersonAudit from './PersonAudit';
const { Option } = Select;

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			status: -1,
			editData: {},
			gameList: [],
			list: [],
			type:1,//1不文明视频 2文明视频
			open:0,
			sh_title:"",
			video_type:1,//不文明视频 2文明视频
		}
		this.columns = [
			{
				title: '赛事名称',
				dataIndex: 'gamename',
				align: 'center',
			}, {
				title: '用户姓名',
				dataIndex: 'userName',
				align: 'center',
			}, {
				title: '手机号',
				dataIndex: 'mobile',
				align: 'center',
			}, {
				title: '文明驾驶视频',
				dataIndex: 'video',
				align: 'center',
				render: src => (
					<video src={src} controls style={{ height: 100, width: 200, }} />
				)
			}, {
				title: '不文明驾驶视频',
				dataIndex: 'no_video',
				align: 'center',
				render: src => (
					<video src={src} controls style={{ height: 100, width: 200, }} />
				)
			}, {
				title: '审核状态',
				dataIndex: 'status',
				align: 'center',
				render:(status,_) => (
					_.game_status==2?
					<span className='color3'>已结束</span>
					:
					<React.Fragment>
						{status == 0 && <span className='color1'>待审核</span>}
						{status == 1 && <span className='color2'>评分中</span>}
						{status == 2 && <span className='color3'>已审核</span>}
					</React.Fragment>
				)
			}, {
				title: '文明视频得分',
				dataIndex: 'video_score',
				align: 'center',
				className: 'pubname',
				render: (score, item) => (
					<span className='color1' onClick={() => {
						this.setState({
							id: item.id,
							visible: true,
							type:2
						})
					}}>{parseFloat(score).toFixed(2)}</span>
				)
			}, {
				title: '不文明视频得分',
				dataIndex: 'no_video_score',
				align: 'center',
				className: 'pubname',
				render: (score, item) => (
					<span className='color1' onClick={() => {
						this.setState({
							id: item.id,
							visible: true,
							type:1
						})
					}}>{parseFloat(score).toFixed(2)}</span>
				)
			}, {
				title: '最终得分',
				dataIndex: 'score',
				align: 'center',
				className: 'pubname',
				
			}, {
				title: '操作',
				dataIndex: 'id',
				align: 'center',
				width: '15%',
				render: (id, item) => (
					<div className='flexAllCenter pubbtnbox'>
						{(item.status==1 || item.game_status==2) ? 
						"-"
						:	
							<>
							{this.state.open==1 && 
								<Popconfirm
									title="提示"
									description="请选择视频类型?"
									okText="文明视频"
									cancelText="不文明视频"
									onCancel={()=>{
										this.setState({
											editData: item,
											shVisible: true,
											video_type:1
										})
									}}
									onConfirm={()=>{
										this.setState({
											editData: item,
											shVisible: true,
											video_type:2
										})
									}}
								>
									<p className='color3'>{this.state.sh_title}</p>
								</Popconfirm>
								}
								<p onClick={this.OnCheck.bind(this, id)}>AI重审</p>
							</>
						}
					</div>
				)
			}
		]
	}
	componentDidMount() {
		// 获取所有大赛
		this.getGame()
		// 获取扣分行为
		global.Ajax.hhtc_request('admin/entry/badbehavior', {}).then(res => {
			if (res.code == 1) {
				this.setState({
					list: res.data,
				})
			}
		})
	}
	OnCheck(id) {
		global.Ajax.hhtc_request('admin/entry/airetrial', { id }).then(res => {
			if (res.code == 1) {
				message.success(res.msg, 1.2)
				this.refresh()
			} else {
				message.error(res.msg, 1.2)
			}
		})
	}
	getGame() {
		let req = {
			name: '',
			status: '',
		}
		global.Ajax.hhtc_request('admin/game/excel', req).then(res => {
			if (res.code == 1) {
				this.setState({
					gameList: res.data,
					game_id:res.data.length>0?res.data[0].id:0
				},()=>{
					this.refresh();
				})
			}
		})
	}
	refresh(page) {
		this.tabblelist.onRefresh(page)
		// this.getGame()
	}
	getList(page, callback) {

		var req = {
			page: page,
			size: this.state.size,
			name: this.state.username || '',
			game_id: this.state.game_id || '',
			status: this.state.status,
		}
		if(req.game_id==0){
			callback({code:1,msg:"",data:{all:0,datas:[]}})
			return;
		}
		global.Ajax.hhtc_request('admin/entry/list', req).then(res => {
			if(res.code==1 && res.data.all>0){
					this.setState({
						open:res.data.datas[0].open,
						sh_title:res.data.datas[0].sh_title
					})
			}
			callback(res)
		})
	}
	// 搜索
	search(name) {
		this.setState({
			username: name,
		}, () => {
			this.refresh(1)
		})
	}
	onRefresh(page, callback) {
		this.getList(page, callback)
	}
	render() {
		const { gameList, list } = this.state;
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<CustomInput
						placeholder='请输入用户姓名、手机号'
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value)
						}}
					/>
					<Select  placeholder='请选择赛事' className='pubSelt borderbai marginr12' style={{ width: 160 }} 
					value={this.state.game_id}
					onChange={(game_id) => {
						this.setState({
							game_id,
						}, () => {
							this.refresh(1)
						})
					}}>
						{gameList.map((item, index) => (
							<Option value={item.id} key={String(index)}>{item.name}</Option>
						))}
					</Select>
					<Select allowClear placeholder='请选择状态' className='pubSelt borderbai marginr12' style={{ width: 160 }} onChange={(status) => {
						if (status == undefined) {
							status = -1;
						}
						this.setState({
							status,
						}, () => {
							this.refresh(1)
						})
					}}>
						<Option value={1}>评分中</Option>
						<Option value={2}>已结束</Option>
					</Select>
					<Button type='primary' loading={this.state.loading} onClick={this.down}>导出EXECL</Button>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>报名列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
					/>
				</div>
				{/* 得分情况 */}
				<CustomModal
					visible={this.state.visible}
					width={1440}
					onCancel={this.onCancel}
					// title='得分情况'
					title={`${this.state.type == 1 ? '不' : ''}文明视频得分情况`}
				>
					<ScoreList type={this.state.type} id={this.state.id} />
				</CustomModal>
				{/* 人工审核 */}
				<CustomModal
					visible={this.state.shVisible}
					width={840}
					onCancel={this.onCancel}
					
					title={`${this.state.sh_title}(${this.state.video_type == 1 ? '不' : ''}文明视频)`}
				>
					<PersonAudit onCancel={this.onCancel} onOk={this.onOk} data={this.state.editData} type={this.state.video_type} />
				</CustomModal>
				{/* IE导出EXECL */}
				<div style={{ width: 0, height: 0, overflow: 'hidden', }}>
					<table id='table' style={{ height: 0, fontFamily: '微软雅黑' }}>
						<tbody>
							<tr style={{ textAlign: 'center' }}>
								<td>赛事名称</td>
								<td>用户姓名</td>
								<td>手机号</td>
								<td>文明驾驶视频</td>
								<td>不文明驾驶视频</td>
								<td>审核状态</td>
								<td>得分</td>
							</tr>
							{this.state.rowIE && this.state.rowIE.map((item, index) => (
								<tr style={{ textAlign: 'center' }} key={String(index)}>
									<td>{item.gamename}</td>
									<td>{item.userName}</td>
									<td>{item.mobile}</td>
									<td>
										<a href={item.video}>{item.video}</a>
									</td>
									<td>
										<a href={item.no_video}>{item.no_video}</a>
									</td>
									<td>
										{item.status == 0 ? '待审核' : ''}
										{item.status == 1 ? '评分中' : ''}
										{item.status == 2 ? '已结束' : ''}
									</td>
									<td>{item.score || 0}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</React.Fragment>
		)
	}
	onFinish = (req) => {
		console.log(req);
		this.setState({
			loading: true,
		}, () => {
			req.id = this.state.editData.id;
			req.behavior = req.behavior ? req.behavior.join(',') : '';
			req.thumb = this.state.thumb;
			global.Ajax.hhtc_request('admin/entry/artificialexamine', req).then(res => {
				if (res.code == 1) {
					message.success(res.msg, 1.2);
					this.refresh(1)
					this.onCancel()
				} else {
					message.error(res.msg, 1.2)
				}
				setTimeout(() => {
					this.setState({
						loading: false,
					})
				}, 1500);
			})
		})
	}

	onOk = () => {
		this.refresh(1)
		this.onCancel()
	}
	onCancel = () => {
		this.setState({
			visible: false,
			shVisible: false,
			id: undefined,
			thumb: '',
			editData: {},
		})
	}
	//  获取当前使用浏览器
	getExplorer() {
		var explorer = window.navigator.userAgent;
		//ie 
		if (explorer.indexOf("MSIE") >= 0) {
			return 'ie';
		} else if (explorer.indexOf("Firefox") >= 0) {
			return 'Firefox';
		} else if (explorer.indexOf("Chrome") >= 0) {
			return 'Chrome';
		} else if (explorer.indexOf("Opera") >= 0) {
			return 'Opera';
		} else if (explorer.indexOf("Safari") >= 0) {
			return 'Safari';
		}
	}
	down = () => {
		this.setState({
			loading: true,
		}, () => {
			let req = {
				name: this.state.username || '',
				status: this.state.status,
				game_id: this.state.game_id || '',
			}
			global.Ajax.hhtc_request('admin/entry/excel', req).then(res => {
				if (res.code == 1) {
					let row = res.data;
					if (this.getExplorer() == 'ie' || this.getExplorer() == undefined) {  //ie浏览器
						this.setState({
							rowIE: row,
						}, () => {
							this.exportExeclForIE()
						})
					} else {
						this.exportExecl(row)
					}
				}
				setTimeout(() => {
					this.setState({
						loading: false,
					})
				}, 1500);
			})
		})
	}
	// IE浏览器导出execl
	exportExeclForIE() {
		var curTbl = document.getElementById('table');
		var oXL;
		try {
			oXL = new window.ActiveXObject("Excel.Application"); //创建AX对象excel
		} catch (e) {
			alert("无法启动Excel!请修改IE配置\n\n如果您确定您的电脑中已经安装了Excel，" + "那么请调整IE的安全级别。\n\n具体操作：\n\n" + "工具 → Internet选项 → 安全 → 自定义级别 → 对没有标记为安全的ActiveX进行初始化和脚本运行 → 启用");
			return false;
		}
		var oWB = oXL.Workbooks.Add(); //获取workbook对象
		var oSheet = oWB.ActiveSheet;//激活当前sheet
		var sel = document.body.createTextRange();
		sel.moveToElementText(curTbl); //把表格中的内容移到TextRange中
		sel.select(); //全选TextRange中内容
		sel.execCommand("Copy");//复制TextRange中内容
		oSheet.Paste();//粘贴到活动的EXCEL中
		oXL.Visible = true; //设置excel可见属性
		var fname = oXL.Application.GetSaveAsFilename("报名信息.xls", "Excel Spreadsheets (*.xls), *.xls");
		oWB.SaveAs(fname);
		oWB.Close();
		oXL.Quit();
	}
	// 非IE浏览器导出execl
	exportExecl(row) {
		let data = `<tr style="text-align: center;">
            <td>赛事名称</td>
            <td>用户姓名</td>
            <td>手机号</td>
            <td>文明驾驶视频</td>
            <td>不文明驾驶视频</td>
            <td>审核状态</td>
            <td>得分</td>
        </tr>`;
		for (let i in row) {
			let item = row[i];
			data += `<tr style="text-align: center;">
                <td>${item.gamename}</td>
                <td>${item.userName}</td>
                <td>${item.mobile}</td>
                <td>
					<a href="${item.video}">${item.video}</a>
				</td>
                <td>
					<a href="${item.no_video}">${item.no_video}</a>
				</td>
                <td>
					${item.status == 0 ? '待审核' : ''}
					${item.status == 1 ? '评分中' : ''}
					${item.status == 2 ? '已结束' : ''}
				</td>
                <td>${item.score || 0}</td>
            </tr>`
		}

		var str = `<table style="font-family: '微软雅黑';"><tbody>${data}</tbody></table>`;
		var worksheet = 'Sheet1'
		var uri = 'data:application/vnd.ms-excel;base64,';
		//下载的表格模板数据
		var template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
                xmlns:x="urn:schemas-microsoft-com:office:excel"
                xmlns="http://www.w3.org/TR/REC-html40">
                <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                <x:Name>${worksheet}</x:Name>
                <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                </head><body>${str}</body></html>`;
		//下载模板
		function base64(s) { return window.btoa(unescape(encodeURIComponent(s))) }
		let link = document.createElement('a');
		link.href = uri + base64(template);
		link.download = '报名信息.xlsx';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}
