import React from 'react';
import { Input, Button, Form, message,Select, Row, Col } from 'antd';
import SelectList from '../../common/SelectList'
const { Option } = Select;
export default class PersonAudit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pwd: '',
            durationscore:0,
            duration:0,
            ratio:0,
            kgb:0,
            videoTime:0,
            videoHeight:0,
            videoWidth:0
        }
    }
    componentDidMount()
    {
        global.Ajax.hhtc_request("admin/rule/getrule",{}).then(res=>{
            if(res.code==1){
                this.setState({
                    duration:res.data.duration,
                    durationscore:res.data.durationscore,
                    ratio:res.data.ratio,
                    kgb:res.data.width/res.data.height
                },()=>{
                    this.setBaseScore();
                })
                this.formRef.setFieldsValue({
                    xiyan: res.data.xiyan,
                    dianhua: res.data.dianhua,
                    anquandai: res.data.anquandai,
                    fangxiangpan:res.data.fangxiangpan,
                    kanqianfang:res.data.kanqianfang,
                    dahaqian: res.data.dahaqian,
                    biyan: res.data.biyan,
                    ditou: res.data.ditou
                })
            }
        })
    }
    setBaseScore()
    {   
        if(this.state.duration==0 || this.state.videoWidth==0){
            return;
        }
       
        //计算时长得分
        var shichang=this.state.durationscore;
        if(this.state.durationscore>this.state.videoTime){
            //如果视频时长大于等于要求时长 则满分 反之按比例扣分
            var bl=this.state.videoTime/this.state.durationscore;
            shichang=(shichang*bl).toFixed(2);
        }
        //计算宽高比得分
        var kgb=this.state.videoWidth/this.state.videoHeight;
        var kgb_score=this.state.ratio;
        if(kgb<this.state.kgb){
            var bl=(kgb/this.state.kgb);
            kgb_score=(bl*kgb_score).toFixed(2);
        }
        this.formRef.setFieldsValue({
            duration:parseFloat(shichang),
            ratio:parseFloat(kgb_score)
        })
    }
    onFinish = (req) => {
        if(!this.videoRef.paused){
            return message.error("请先暂停视频!")
        }
        req.thumb=this.state.thumb
        req.zongfen=req.anquandai+req.biyan+req.dahaqian+req.dianhua+req.ditou+
        parseFloat(req.duration)+req.fangxiangpan+req.kanqianfang+parseFloat(req.ratio)+req.xiyan;
        req.id=this.props.data.id
        req.type=this.props.type;
        this.setState({
            loading: true,
        },()=>{
            var url='admin/entry/artificialexamine';
            
            global.Ajax.hhtc_request(url,req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onOk()
                }else{
                    message.error(res.msg,1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    getVideoImg(e) {
		window.getImg(e, (url,e1) => {
			console.log('截取图片帧---',url);
			this.setState({
				thumb: url,
			})
		})
	}
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    ref={ref=>this.formRef=ref}
                    onFinish={this.onFinish}
                    labelCol={{ flex: '82px' }}
                >
                    <video ref={dom => this.videoRef = dom} id='video' 
                    src={this.props.type==1?this.props.data.no_video:this.props.data.video} 
                    crossOrigin='anonymous' 
                    style={{ width: '100%', maxHeight: 300, marginBottom: 10 }} controls
                    
                        onLoadedData={(e)=>{
                            console.log("加载完毕",this.videoRef.paused,e.target.videoWidth,e.target.videoHeight,e.target.videoWidth/e.target.videoHeight)
                           this.setState({
                                videoTime:e.target.duration,
                                videoWidth:e.target.videoWidth,
                                videoHeight:e.target.videoHeight
                           },()=>{
                            this.setBaseScore();
                           })
                        }}
                        
                        onPause={(e) => {
                            
                            this.paused = true;
                            this.getVideoImg(e)
                        }}
                        onTimeUpdate={(e) => {
                            console.log(e.getTime() )
                            if (this.paused) {
                                // this.getVideoImg(e)
                            }
                        }}
                        onPlay={() => {
                            this.paused = false;
                        }}
                        onCanPlay={this.getVideoImg.bind(this)}
                    />
                    <Row gutter={20} >
                        <Col span={12} >
                            <Form.Item labelCol={{span:8}} label='时长得分' name='duration' rules={[{ required: true, message: '请填写视频时长得分！' }]}>
                                <Input autoComplete='off' placeholder='请填写视频时长得分！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item labelCol={{span:8}} label='分辨率得分' name='ratio' rules={[{ required: true, message: '请填写视频分辨率得分！' }]}>
                                <Input autoComplete='off' placeholder='请填写视频分辨率得分！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item labelCol={{span:8}} label='吸烟得分' name='xiyan' rules={[{ required: true, message: '请填写吸烟分数！' }]}>
                                <Input autoComplete='off' placeholder='请填写吸烟分数！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item labelCol={{span:8}} label='闭眼得分' name='biyan' rules={[{ required: true, message: '请填写闭眼分数！' }]}>
                                <Input autoComplete='off' placeholder='请填写闭眼分数！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item labelCol={{span:8}} label='打电话得分' name='dianhua' rules={[{ required: true, message: '请填写打电话分数！' }]}>
                                <Input autoComplete='off' placeholder='请填写打电话分数！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item labelCol={{span:8}} label='低头得分' name='ditou' rules={[{ required: true, message: '请填写低头分数！' }]}>
                                <Input autoComplete='off' placeholder='请填写低头分数！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='未系安全带' labelCol={{span:8}} name='anquandai' rules={[{ required: true, message: '请填写未系安全带分数！' }]}>
                                <Input autoComplete='off' placeholder='请填写未系安全带分数！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='双手离开方向盘' labelCol={{span:8}} name='fangxiangpan' rules={[{ required: true, message: '请填写双手离开方向盘分数！' }]}>
                                <Input autoComplete='off' placeholder='请填写双手离开方向盘分数！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item labelCol={{span:8}} label='未看正前方' name='kanqianfang' rules={[{ required: true, message: '请填写未看正前方分数！' }]}>
                                <Input autoComplete='off' placeholder='请填写未看正前方分数！' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item labelCol={{span:8}} label='打哈欠' name='dahaqian' rules={[{ required: true, message: '请填写打哈欠分数！' }]}>
                                <Input autoComplete='off' placeholder='请填写打哈欠分数！' />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Form.Item labelCol={{span:4}} label='异常说明' name='description' rules={[{ required: true, message: '请填写异常说明！' }]}>
                        <Input.TextArea  autoSize={{
                        minRows: 1,
                        maxRows: 5,
                        }} autoComplete='off' placeholder='请填写异常说明' />
                    </Form.Item>
                    <Button type='primary' htmlType='submit' style={{ display: 'block', margin: '0 auto' }} loading={this.state.loading}>提交</Button>
                </Form>
            </div>
        )
    }
}